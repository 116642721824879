import React from 'react';
import MembershipContent from '../components/membership/MembershipContent';

const Membership = () => {
  return (
    <>
      <MembershipContent />
    </>
  );
};

export default Membership;
